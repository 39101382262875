import Link from "next/link";

export default function Hero() {
	return (
		<div className="section full-height over-hide">
			<div className="hero-center-section">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 z-index-3 text-load-anime">
							<h3 className="mb-2">지금, 이대로 3초 안에 성적 분석</h3>
							<p className="mb-4 weight-600 lead color-light">성적 분석을 통해 진학 목표를 설정해 보세요</p>
							<Link href="/testanalysis" className="btn btn-blue btn-radius parallax-elements js-tilt">
								지금 분석하기<i className="uil uil-arrow-right size-24 ml-2"></i>
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="hero-img-back-section hero-img-1-anime"></div>
		</div>
	);
}
