import Image from "next/image";
import homeService1 from "/public/img/home-service-1.png";
import homeService2 from "/public/img/home-service-2.png";
import homeService3 from "/public/img/home-service-3.png";
import homeService4 from "/public/img/home-service-4.png";
import Link from "next/link";

export default function Services() {
	return (
		<div className="section padding-top-bottom-100 over-hide">
			<div className="container-fluid px-xl-5">
				<div className="row justify-content-center">
					<div
						className="col-6 col-md-3 col-lg img-wrap px-md-0"
						data-scroll-reveal="enter bottom move 50px over 0.4s after 0.3s"
					>
						<Image src={homeService1} alt="" />
					</div>
					<div
						className="col-6 col-md-3 col-lg img-wrap px-md-0"
						data-scroll-reveal="enter bottom move 50px over 0.4s after 0.3s"
					>
						<Image src={homeService2} alt="" />
					</div>
					<div
						className="col-6 col-md-3 col-lg img-wrap px-md-0"
						data-scroll-reveal="enter bottom move 50px over 0.4s after 0.3s"
					>
						<Image src={homeService3} alt="" />
					</div>
					<div
						className="col-6 col-md-3 col-lg img-wrap px-md-0"
						data-scroll-reveal="enter bottom move 50px over 0.4s after 0.3s"
					>
						<Image src={homeService4} alt="" />
					</div>
					<div
						className="col-lg-3 pt-4 pt-lg-0 align-self-center pl-lg-4 pl-xl-5"
						data-scroll-reveal="enter bottom move 50px over 0.4s after 0.3s"
					>
						<h4 className="mb-3">SERVICE</h4>
						<p className="mb-4">
							모의고사, 수능 성적 분석부터 케어까지, 이대로는 대학교 진학까지 여러분과 함께합니다.
						</p>
						<Link href="/services" className="btn btn-blue btn-radius parallax-elements js-tilt">
							자세히 보기<i className="uil uil-arrow-right size-20 ml-2"></i>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
